@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.disbursement-onboarding-container {
    .services{
      letter-spacing: 0.002em;
      @include text(normal, 12px, 16px, $gray-500);
    }
    .profile{
        margin: 28px 0 32px 0;
        img {
            margin-right: 24px;
        }
        letter-spacing: 0.002em;
        @include text(600, 24px, 16px, $gray-500);
      }
    .items {
        display: flex;
        border: 2px solid $spn-100-color;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 16px 20px 16px;
        margin-bottom: 16px;
        .sqr {
            background: rgba(23, 95, 255, 0.2);
            border-radius: 5px;
            height: 32px;
            width: 32px;
            margin-right: 16px;
        }
        .name{
          @include text(600, 16px, 19px, $spn-900-color);
        }
        .desc{
          @include text(normal, 12px, 16px, $spn-600-color);
        }
    }
    .items:hover {
        border: 2px solid $primary-color;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
    }
    .items-active {
        border: 2px solid $primary-color;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .items-content, .data-content{
        padding-top: 40px;
    }
    .items-content{
        padding-right: 32px;
        border-top: 1px solid $spn-100-color;
    }
    .data-content{
        border-left: 1px solid $spn-100-color;
        border-top: 1px solid $spn-100-color;
    }
    .form-content {
        margin-top: 40px;
        padding-left: 42px;
        padding-right: 22px;
        .choose {
            @include text(600, 18px, 22px, $pure-black);
            margin-bottom: 24px;
         }
        label{
           @include text(normal, 14px, 14px, $spn-700-color);
        }
        .aml-content{
            margin-top: 24px;
            .aml {
                img{
                    margin-left: 8px;
                }
                @include text(400, 14px, 16px, $spn-900-color);
            }
        }
    }
    .table-responsiveness{
        padding-left: 42px;
        padding-right: 22px;
        .title-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .search-container {
                width: 300px;
                position: relative;
                i {
                  position: absolute;
                  top: 25%;
                  right: 12px;
                  color: #24292e;
                }
              }
        }
        .choose {
            @include text(600, 18px, 22px, $pure-black);
            margin-bottom: 24px;
            margin-top: 40px
         }
         .table > thead {
            vertical-align: middle;
            border-width: unset;
            border-color: transparent;
        }
    table {
       thead {
           tr{
               height: 48px;
               align-items: center; 
               border-width: 0 !important
            }
        td{
            img{
                margin-right: 20px;
                margin-bottom: 3px
            }
            @include text(600, 14px, 16px, $spn-900-color);
        }
       }
       tbody{
        tr{
            height: 48px;
            align-items: center;
        }
           td{
               img{
                   margin-right: 20px;
                   margin-bottom: 3px
               }
             @include text(normal, 14px, 16px, $spn-700-color);
           }
           tr:hover{
             background-color: $spn-050-color;
           }
           
       }
    }
  }
  .btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 50px;
      margin-top: 56px;
      .cancel-btn{
        background: $gray-060;
        width: 150px;
        height: 40px;
        color: $dark-180
      }
      .proceed-btn {
        background-color: $primary-color;
        color: $pure-white;
        width: 150px;
        height: 40px;
      }
  }
  .form-control {
      height: 48px;
  }
}