.deactivate-modal-container {
  p {
    font-size: 14px;
  }
  .cancel-btn {
    margin: 0 24px;
  }
}
button.submit-btn {
  width: 150px;
}
.modal__body {
  padding: 20px 16px 60px 16px !important;
}
