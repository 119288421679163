.disbursement-instance-container {
  .search-container {
    width: 300px;
    position: relative;
    input {
      background-color: rgba(240, 241, 243, 1);
    }
    i {
      position: absolute;
      top: 25%;
      right: 12px;
      color: #24292e;
    }
  }
  .copy-id{
    cursor: pointer;
  }
  // .filterDropdown {
  //   &__dropdownToggle {
  //     background-color: #eee;
  //     color: #7e7e7e;
  //     width: 122px;
  //     height: 40px;

  //     &:focus {
  //       background-color: #eee;
  //       color: #7e7e7e;
  //       box-shadow: none;
  //     }

  //     &:hover {
  //       background-color: #eee !important;
  //     }
  //   }
  //   &__dropdownBtn {
  //     color: #7e7e7e;
  //   }
  // }
}
