@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.notification-container {
  display: inline-flex;
  align-items: center;
  position: fixed;
  z-index: 2000;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  img {
    margin-right: 18px;
  }
  .success {
    padding: 18px;
    max-width: 601px;
    display: flex;
    justify-content: space-evenly;
    @include text(normal, 14px, 14px, $spg-700-color);
    background-color: $spg-100-color;
  }
  .warning {
    max-width: 601px;
    padding: 18px;
    display: flex;
    justify-content: space-evenly;
    @include text(normal, 14px, 14px, $spg-700-color);
    background-color: $spo-100-color;
  }
  .error {
    max-width: 601px;
    padding: 18px;
    display: flex;
    justify-content: space-evenly;
    @include text(normal, 14px, 14px, $spr-600-color);
    background-color: $spr-100-color;
  }
  span {
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    padding-left: 20px;
  }
}
