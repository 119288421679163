@import "assets/styles/bootstrap-override.scss";
@import "~vgg-ui-react/dist/styles/index.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: #1354d3 $spn-200-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $spn-200-color;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #1354d3;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #0e347e;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-gray {
  background-color: #e1e1e1;
}

.pagination-container {
  padding: 32px 0 !important;
  .pagination-item {
    font-size: 14px !important;

    &.selected {
      color: #175fff;
      background-color: rgba(23, 95, 255, 0.2);
    }
  }
}

.pagination-nav {
  font-size: 14px !important;
}

.shimmer-line {
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 8px;
}
