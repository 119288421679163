@import "assets/styles/common/colors.scss";
.stepper-wrapper-horizontal {
  display: flex;
  justify-content: space-between;

  .step-wrapper {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }

  .step-number {
    border-radius: 50%;
    border: 1px solid $gray-300;
    width: 30px;
    height: 30px;
    padding: 3px;
    text-align: center;
    margin-bottom: 1.2rem;
  }

  .step-description {
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: $gray-400;
  }

  .divider-line {
    height: 3px;
    background-color: $gray-100;
    position: absolute;
    top: 20%;
    left: 70%;
  }

  .divider-line-2 {
    width: 296%;
  }

  .divider-line-3 {
    width: 125%;
  }

  .divider-line-4 {
    width: 70%;
  }

  .divider-line-5 {
    width: 60%;
  }
}

.stepper-wrapper-vertical {
  .step-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    position: relative;
  }

  .step-number {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    padding: 4.5px 4px 4px;
    text-align: center;
    font-size: 95%;
  }

  .step-description {
    margin-left: 1.2rem;
    padding-bottom: 3px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: $gray-500;
  }

  .divider-line {
    height: 17px;
    width: 3px;
    background-color: $gray-100;
    position: absolute;
    top: 100%;
    left: 6.4%;
  }
}

.step-number-selected {
  border: 1px solid $gray-200;
  color: $pure-white;
}

.step-number-disabled {
  border: 1px solid $gray-400;
}

.step-description-active {
  font-weight: 500 !important;
  color: $primary-color !important;
}
