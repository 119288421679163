@import "assets/styles/common/colors.scss";
.action-dropdown {
  width: 120px;
  border: 1px solid $dark-050;
  box-sizing: border-box;
  border-radius: 4px;

  .dropdown-item {
    height: 40px;
    left: 0px;
    top: 0px;
    &:hover {
      background: $light-100;
    }
  }
}
.ri-more-2-fill,
.ri-more-fill {
  color: $dark-100;
}

.dd-icon {
  cursor: pointer;
}
