@import "assets/styles/common/colors.scss";
.custom-dropdown-container {
  display: unset;
}
.filter-dropdown {
  width: 308px;
  border: 1px solid $dark-050;
  box-sizing: border-box;
  border-radius: 4px;
}
